/*** Reset all ***/
* {
	margin: 0;
	padding: 0;
	outline: 0;
	border: 0;
	
	font-weight: inherit;
	font-size: 1.0em;
	text-decoration: none;
	
	line-height: 1.25em;
	letter-spacing: 0.0em;
	list-style-type: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
html, body 
{
	cursor: default;
}
input, textarea {
    resize: none;
    display: block;
    font-size: inherit;
    font-family: inherit;
    background: transparent;
    -webkit-touch-callout: default;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    -o-user-select: text;
    user-select: text;
}
input:invalid,
textarea:invalid {
    box-shadow: none;
}

/* Scrollbar */
::-webkit-scrollbar {
	width: 6px;
}
::-webkit-scrollbar-button {
    height:0;
    width:0;
}
::-webkit-scrollbar-track {
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.4);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0,0,0,0.6);
}
::-webkit-scrollbar-thumb:active {
    background-color: rgba(0,0,0,0.6);
}